export default {
  APPROVE_ANT: Symbol('APPROVE_ANT'),
  STAKE_AND_ACTIVATE_ANT: Symbol('STAKE_AND_ACTIVATE_ANT'),
  ACTIVATE_ANT: Symbol('ACTIVATE_ANT'),
  UNLOCK_ACTIVATION: Symbol('UNLOCK_ACTIVATION'),
  DEACTIVATE_ANT: Symbol('DEACTIVATE_ANT'),
  WITHDRAW_ANT: Symbol('WITHDRAW_ANT'),
  DRAFT_GUARDIAN: Symbol('DRAFT_GUARDIAN'),
  COMMIT_VOTE: Symbol('COMMIT_VOTE'),
  REVEAL_VOTE: Symbol('REVEAL_VOTE'),
  LEAK_VOTE: Symbol('LEAK_VOTE'),
  APPROVE_FEE_DEPOSIT: Symbol('APPROVE_FEE_DEPOSIT'),
  APPEAL_RULING: Symbol('APPEAL_RULING'),
  CONFIRM_APPEAL: Symbol('CONFIRM_APPEAL'),
  EXECUTE_RULING: Symbol('EXECUTE_RULING'),
  SETTLE_PENALTIES: Symbol('SETTLE_PENALTIES'),
  HEARTBEAT: Symbol('HEARTBEAT'),
  SETTLE_REWARD: Symbol('SETTLE_REWARD'),
  SETTLE_APPEAL_DEPOSIT: Symbol('SETTLE_APPEAL_DEPOSIT'),
  CLAIM_REWARDS: Symbol('CLAIM_REWARDS'),
  CLAIM_SUBSCRIPTION_FEES: Symbol('CLAIM_SUBSCRIPTION_FEES'),
}
